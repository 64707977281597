<template>
    <div class="indexStyle">
        <div class="head_img"  :style="`height:${banner_height}px`"></div>
        <div class="trait">
            <div class="h2">功能特征</div>
            <div class="content">峰盛科技数字电商-在线商城系统</div>
            <ul>
                <li v-for="(item,index) in traitList" :key="index">
                    <img :src="item.img" alt="">
                    <div class="title">{{item.title}}</div>
                    <div class="content">
                        <p>{{item.content}}</p>
                    </div>
                </li>
                <div class="list" v-for="item in (rowNum - traitList.length % rowNum)"><div v-if="traitList.length % rowNum > 0"></div></div>
                
            </ul>
        </div>
        <userSide :userShow="userShow" :list="mobileList" />
        <computerCase :deskShow="deskShow" :title="'后台截图预览'" :list="caseList" :bgColor="'#fff'" />
        <correlationCase :caseShow="caseShow" :caseList="storeList" />
    </div>
</template>

<script>
import correlationCase from '@/components/correlationCase'
import computerCase from '@/components/computerCase'
import userSide from '@/components/userSide'

export default {
    components:{correlationCase,computerCase,userSide},
    data(){
        return{
            traitList:[
                {
                    img:require('@/assets/image/onlineStore/icon.png'),
                    title:'多端适配',
                    content:'微信公众号、微信小程序、H5网页',
                },
                {
                    img:require('@/assets/image/onlineStore/icon2.png'),
                    title:'营销功能',
                    content:'优惠券、秒杀、砍价、满减、限时折扣',
                },
                {
                    img:require('@/assets/image/onlineStore/icon3.png'),
                    title:'分销功能',
                    content:'三级分销佣金，推广海报团队奖励',
                },
                {
                    img:require('@/assets/image/onlineStore/icon4.png'),
                    title:'积分功能',
                    content:'积分签到，支付，奖励，增加用户消费粘性',
                },
                {
                    img:require('@/assets/image/onlineStore/icon5.png'),
                    title:'商城装修',
                    content:'可自定义前端呈现排版、自定义商品组',
                },
                {
                    img:require('@/assets/image/onlineStore/icon6.png'),
                    title:'多种发货模式',
                    content:'物流快递、到店/自提（核销）、自动发货',
                },
                {
                    img:require('@/assets/image/onlineStore/icon7.png'),
                    title:'多商户',
                    content:'多商户B2B2C、自营B2C运营模式',
                },
                {
                    img:require('@/assets/image/onlineStore/icon11.png'),
                    title:'数据统计',
                    content:'订单实时统计并且实时查询订单，数据对比',
                },
                {
                    img:require('@/assets/image/onlineStore/icon8.png'),
                    title:'代码开源',
                    content:'PHP语言，前后端分离，源码支持二次开发',
                },
                {
                    img:require('@/assets/image/onlineStore/icon9.png'),
                    title:'完善后台',
                    content:'经过多年实操运营沉淀的后台管理体验',
                },
                {
                    img:require('@/assets/image/onlineStore/icon10.png'),
                    title:'自定义专题',
                    content:'支持自定义专题页面，针对特定商品进行装修',
                },
                {
                    img:require('@/assets/image/onlineStore/icon12.png'),
                    title:'特色功能',
                    content:'全民股东，团队分红，全返，周期购',
                },

            ],
            //电商
            storeList:[
                {
                    img:require('@/assets/image/case/9.png'),
                    title:'电商系统',
                    content1:'热风微商城',
                    content2:'专注为企业搭建优质的电商平台建设'
                },
                {
                    img:require('@/assets/image/case/10.png'),
                    title:'电商系统',
                    content1:'凯德星商城',
                    content2:'多种运营模式组合，构建私域运营体系'
                },
                {
                    img:require('@/assets/image/case/11.png'),
                    title:'电商系统',
                    content1:'尚璇电商',
                    content2:'助力为企业快速获取流量，实现销量突破'
                },
                {
                    img:require('@/assets/image/case/12.png'),
                    title:'电商系统',
                    content1:'亿人电商',
                    content2:'帮助企业低成本快速构建在线商城'
                },
            ],
            mobileList:[
                require('@/assets/image/onlineStore/slideshow1.png'),
                require('@/assets/image/onlineStore/slideshow2.png'),
                require('@/assets/image/onlineStore/slideshow3.png'),
                require('@/assets/image/onlineStore/slideshow4.png'),
            ],
            caseList:[
                require('@/assets/image/onlineStore/case1.png'),
                require('@/assets/image/onlineStore/case2.png'),
                require('@/assets/image/onlineStore/case3.png'),
            ],
            rowNum:6,
            caseShow:false,
            userShow:false,
            deskShow:false,
            windowWidth:document.documentElement.clientWidth,
            banner_height: document.documentElement.clientWidth > 1024 ?
            document.documentElement.clientWidth * 0.21 : document.documentElement.clientWidth * 0.29,
        }
    },
     mounted(){
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
                if(this.windowWidth >= 1024){
                    this.banner_height = this.windowWidth * 0.21
                }else{
                    this.banner_height = this.windowWidth * 0.29
                }
            })()
        }
        if(this.windowWidth <= 1024){
            this.userShow = true
            this.deskShow = true
            this.caseShow = true
        } 
    },
    methods:{
         // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth <= 1024)return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop

            let user = document.getElementsByClassName('user')
            let user_h2 = user[0].getElementsByClassName('h2')
            let user_content = user[0].getElementsByClassName('content')
            let user_slide = user[0].getElementsByClassName('slide')
            
            let desk = document.getElementsByClassName('desk')
            let slidShow = document.getElementsByClassName('slidShow')
           //开发案例
            let Case = document.getElementsByClassName('case')
            let Case_h2 = Case[0].getElementsByClassName('h2')
            let Case_swiper = Case[0].getElementsByClassName('case-swiper-container')

            if(scrollTop > (user[0].getBoundingClientRect().top + scrollTop) - user[0].offsetHeight /2){
                this.userShow = true
                user_h2[0].classList.add('animated','fadeIn')
                user_content[0].classList.add('animated','fadeIn')
                user_slide[0].classList.add('animated','bounceInLeft')
            }

            if(scrollTop > (desk[0].getBoundingClientRect().top + scrollTop) - desk[0].offsetHeight /2){
                this.deskShow = true
                desk[0].classList.add('animated','fadeIn')
                slidShow[0].classList.add('animated','zoomIn')
            }

            if(scrollTop > (Case[0].getBoundingClientRect().top + scrollTop) - Case[0].offsetHeight / 1.5){
                this.caseShow = true
                Case_h2[0].classList.add('animated','fadeIn')
                Case_swiper[0].classList.add('animated','fadeInUp')
            }
            
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="less" scoped>
    .indexStyle {
        .head_img{
            background: url('~@/assets/image/onlineStore/banner.png') no-repeat;
            background-size: 100%;
        }
        .trait{
            height: 1150px;
            .h2{
                padding: 65px 0 20px;
            }
            .content{
                margin-bottom: 50px;
            }
            ul{
                display: flex;
                flex-wrap: wrap;
                width: 1200px;
                margin: 0 auto;
                li{
                    background: url('~@/assets/image/enterprise/icon(16).png') no-repeat;
                    width: 296px;
                    height: 279px;
                    &:hover{
                        background: url('~@/assets/image/enterprise/icon(15).png') no-repeat;
                    }
                    img{
                        margin: 45px 0 22px 0;
                    }
                    .title{
                        font-size: 22px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 15px;
                    }
                    .content{
                        width: 150px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #666666;
                        line-height: 20px;
                    }
                }
                .list{
                    content: '';
                    width: 296px;
                    height: 279px;
                    border: 1px solid transparent;
                    padding: 5px;
                    overflow: hidden;
                }

            }
        }
    }
@media screen and (max-width: 1024px) {
    .indexStyle {
        .head_img{
            height: 110px;
            background: url('~@/assets/image/customization/mobile_banner.png') no-repeat;
            background-size: 100%;
        }
        .trait{
            height: 657px;
            .h2{
                line-height: inherit;
                padding: 25px 0 8px;
            }
            .content{
                margin-bottom: 15px;
            }
            ul{
                width: 100%;
                justify-content: center;
                li{
                    width: 110px;
                    height: 130px;
                    background-size: 110px 130px;
                    img{
                        max-width: 50%;
                        max-height: 30%;
                        margin: 15px 0 10px 0;
                    }
                    .title{
                        font-size: 13px;
                        margin-bottom: 0;
                    }
                    .content{
                        margin-top: -5px;
                        width: 142px;
                        font-size: 8px;
                        line-height: 20px;
                        transform: scale(0.6);
                        margin-left: -15%;
                    }
                    
                }
                .list{
                    width: 110px;
                    height: 130px;
                }
            }
        }
    }
}
@media screen and (min-width: 1025px) {
    .indexStyle {
        margin-top: 90px;
    }

}
</style>